import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import MaskedInput from "react-text-mask";
import { cnpjMask, phoneMask } from "../../../globals/input-masks";
import { useRef, useState } from "react";
import { validCNPJ } from "../../../globals/helpers";
import { CompanyService } from "../../../services/CompanyService";
import { Api } from "../../../services/api";

type RegisterModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onRegister: () => void;
};

type FormValues = {
  name: string;
  socialName: string;
  cnpj: string;
  image: File | null;
  email: string;
  phone: string;
  paymentType: "hourly" | "monthly" | null;
  paymentValue: number;
  note: string;
};
type FormErrors = {
  name?: string;
  socialName?: string;
  cnpj?: string;
  image?: string;
  email?: string;
  phone?: string;
  paymentType?: string;
  paymentValue?: string;
  note?: string;
};

export function RegisterModal({
  isOpen,
  onClose,
  onRegister,
}: RegisterModalProps) {
  const companyService = new CompanyService(new Api());
  const [form, setForm] = useState<FormValues>({
    name: "",
    socialName: "",
    cnpj: "",
    image: null,
    email: "",
    phone: "",
    paymentType: null,
    paymentValue: 0,
    note: "",
  });
  const [saving, setSaving] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const initialRef = useRef(null);
  const toast = useToast();

  const handleCnpjChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      cnpj: e.target.value,
    });

    if (e.target.value.length === 18 && !validCNPJ(e.target.value)) {
      setFormErrors({
        ...formErrors,
        cnpj: "CNPJ inválido",
      });
    } else {
      setFormErrors({
        ...formErrors,
        cnpj: undefined,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (form.image === null) {
      setFormErrors({
        ...formErrors,
        image: "Imagem é obrigatória",
      });
      return;
    }

    if (form.paymentType === null) {
      setFormErrors({
        ...formErrors,
        paymentType: "Tipo de pagamento é obrigatório",
      });
      return;
    }

    setSaving(true);

    try {
      await companyService.create({
        name: form.name,
        social_name: form.socialName,
        cnpj: form.cnpj,
        email: form.email,
        phone: form.phone,
        logo: form.image,
        payment_type: form.paymentType,
        payment_value: form.paymentValue,
        note: form.note,
      });
      onRegister();
      onClose();
    } catch (error: any) {
      console.log("ERROR AQUI!!!", error);
      toast({
        title: "Erro ao salvar empresa",
        description: "Ocorreu um erro ao salvar a empresa, tente novamente",
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      if (error.response && error.response.status === 422) {
        setFormErrors(error.response.data);
      } else {
        console.error(error);
      }
    }
    setSaving(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="4xl"
      initialFocusRef={initialRef}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Nova Empresa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <FormControl isRequired isInvalid={!!formErrors.name}>
                <FormLabel>Nome</FormLabel>
                <Input
                  type="text"
                  required
                  ref={initialRef}
                  value={form.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                />
                {!!formErrors.name && (
                  <FormErrorMessage color="red">
                    {formErrors.name}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={!!formErrors.socialName}>
                <FormLabel>Razão Social</FormLabel>
                <Input
                  type="text"
                  isRequired
                  value={form.socialName}
                  onChange={(e) =>
                    setForm({ ...form, socialName: e.target.value })
                  }
                />
                {!!formErrors.socialName && (
                  <FormErrorMessage color="red">
                    {formErrors.socialName}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={!!formErrors.cnpj}>
                <FormLabel>CNPJ</FormLabel>
                <Input
                  as={MaskedInput}
                  mask={cnpjMask}
                  guide={false}
                  isRequired
                  value={form.cnpj}
                  onChange={handleCnpjChange}
                />
                {!!formErrors.cnpj && (
                  <FormErrorMessage color="red">
                    {formErrors.cnpj}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={!!formErrors.image}>
                <FormLabel>Imagem</FormLabel>
                <Input
                  type="file"
                  isRequired
                  p={1}
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      setForm({
                        ...form,
                        image: e.target.files[0],
                      });
                    }
                  }}
                />
                {!!formErrors.image && (
                  <FormErrorMessage color="red">
                    {formErrors.image}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={!!formErrors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  isRequired
                  value={form.email}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
                {!!formErrors.email && (
                  <FormErrorMessage color="red">
                    {formErrors.email}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={!!formErrors.phone}>
                <FormLabel>Telefone</FormLabel>
                <Input
                  as={MaskedInput}
                  mask={phoneMask}
                  guide={false}
                  isRequired
                  value={form.phone}
                  onChange={(e) => setForm({ ...form, phone: e.target.value })}
                />
                {!!formErrors.phone && (
                  <FormErrorMessage color="red">
                    {formErrors.phone}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={!!formErrors.paymentType}>
                <FormLabel>Tipo de pagamento</FormLabel>
                <Select
                  onChange={(e) =>
                    setForm({
                      ...form,
                      paymentType: e.target.value as "hourly" | "monthly",
                    })
                  }
                >
                  <option value="">Selecione...</option>
                  <option value="hourly">Por hora</option>
                  <option value="monthly">Mensal</option>
                </Select>
                {!!formErrors.paymentType && (
                  <FormErrorMessage color="red">
                    {formErrors.paymentType}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={!!formErrors.paymentValue}>
                <FormLabel>Valor do pagamento</FormLabel>
                <Input
                  type="number"
                  isRequired
                  value={form.paymentValue}
                  onChange={(e) =>
                    setForm({ ...form, paymentValue: Number(e.target.value) })
                  }
                />
                {!!formErrors.paymentValue && (
                  <FormErrorMessage color="red">
                    {formErrors.paymentValue}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl gridColumn="span 2">
                <FormLabel>Observações</FormLabel>
                <Textarea
                  value={form.note}
                  onChange={(e) => setForm({ ...form, note: e.target.value })}
                />
              </FormControl>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" colorScheme="teal" isLoading={saving}>
              Salvar
            </Button>
            <Button variant="ghost" onClick={onClose} ml={4} disabled={saving}>
              Cancelar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
