import { Box, Button, Flex, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function MenuItem({
  children,
  to,
  path,
}: {
  children: React.ReactNode;
  to: string;
  path?: string;
}) {
  return (
    <Box
      px={4}
      py={2}
      mx={1}
      backgroundColor={path === to ? "teal.700" : ""}
      borderRadius={4}
      transition="background-color 0.1s ease"
      _hover={{ backgroundColor: "teal.700" }}
      _active={{ backgroundColor: "teal.800" }}
    >
      <Link to={to}>{children}</Link>
    </Box>
  );
}

export function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <Box
      bg="teal.600"
      w="100%"
      px={4}
      py={2}
      color="white"
      position="sticky"
      top={0}
      zIndex={10}
    >
      <Flex justify="space-between" w="100%" align="center">
        <Flex direction="row" justify="center" align="center">
          <Flex
            direction="row"
            align="center"
            pr={4}
            mr={4}
            borderRight="1px solid white"
            cursor="default"
          >
            <Image src="/logo.png" alt="Logo" h={8} pr={2} />
            <Box fontSize="xl">Original CRM</Box>
          </Flex>
          <MenuItem to="/" path={path}>
            Dashboard
          </MenuItem>
          <MenuItem to="/companies" path={path}>
            Empresas
          </MenuItem>
          <MenuItem to="/worked-hours" path={path}>
            Horas Trabalhadas
          </MenuItem>
          <MenuItem to="/bills" path={path}>
            Notas Fiscais
          </MenuItem>
          <MenuItem to="/financial" path={path}>
            Financeiro
          </MenuItem>
        </Flex>
        <Button
          colorScheme="red"
          fill="red.500"
          px={8}
          size="sm"
          onClick={logout}
        >
          Sair
        </Button>
      </Flex>
    </Box>
  );
}
