import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFile,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faImage,
} from "@fortawesome/free-regular-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

interface FileIconProps extends Omit<FontAwesomeIconProps, "icon"> {
  extension: string;
}

export function FileIcon({ extension, ...props }: FileIconProps): JSX.Element {
  let icon: IconProp = faFile;

  if (extension === "pdf") {
    icon = faFilePdf;
  }

  if (["jpg", "jpeg", "png", "gif", "svg"].includes(extension)) {
    icon = faImage;
  }

  if (["doc", "docx", "odt"].includes(extension)) {
    icon = faFileWord;
  }

  if (["xls", "xlsx", "ods"].includes(extension)) {
    icon = faFileExcel;
  }

  if (["ppt", "pptx", "odp"].includes(extension)) {
    icon = faFilePowerpoint;
  }

  return <FontAwesomeIcon {...props} icon={icon} />;
}
