import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  useToast,
} from "@chakra-ui/react";
import { AuthService } from "../../services/AuthService";
import { Api } from "../../services/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type Form = {
  email: string;
  password: string;
};

export function LoginPage() {
  const authService = new AuthService(new Api());
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<Form>({
    email: "",
    password: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      authService.getUser().then((response) => {
        if (response.status === 200) {
          localStorage.setItem(
            "user",
            JSON.stringify({
              name: response.data.name,
              email: response.data.email,
            })
          );

          navigate("/");
        }
      });
    }
  });

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await authService.login(form);
      setLoading(false);

      localStorage.setItem("token", response.data.token);
      localStorage.setItem(
        "user",
        JSON.stringify({
          name: response.data.user.name,
          email: response.data.user.email,
        })
      );

      navigate("/");
    } catch (error) {
      setLoading(false);
      toast({
        title: "Erro ao fazer login",
        description: "Verifique suas credenciais e tente novamente",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      minHeight={"100vh"}
      alignItems={"center"}
      justifyContent={"center"}
      bgGradient={"linear(to-br, blue.400, teal.400)"}
    >
      <Box
        p={8}
        width={{ base: "100%", md: "50%", lg: "35%" }}
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
        bg="gray.50"
      >
        <Flex justifyContent="center" alignItems="center" mb={4}>
          <Image src="/logo.png" alt="Logo" h={12} pr={3} />
          <Heading as="h1">Original CRM</Heading>
        </Flex>
        <form onSubmit={handleLogin}>
          <FormControl mb={4}>
            <FormLabel>E-mail</FormLabel>
            <Input
              type="email"
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Senha</FormLabel>
            <Input
              type="password"
              value={form.password}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />
          </FormControl>
          <Button
            type="submit"
            colorScheme="teal"
            width="full"
            isLoading={loading}
          >
            Entrar
          </Button>
          <Button
            type="button"
            colorScheme="teal"
            variant="link"
            width="full"
            mt={4}
            disabled={loading}
          >
            Esqueci minha senha
          </Button>
        </form>
      </Box>
    </Flex>
  );
}
