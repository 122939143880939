import { Heading, useDisclosure } from "@chakra-ui/react";
import { FloatingAddButton } from "../../components/floating-add-button/FloatingAddButton";
import { List } from "./partials/List";
import { RegisterModal } from "./partials/RegisterModal";
import { CompanyService } from "../../services/CompanyService";
import { Api } from "../../services/api";
import { useEffect, useState } from "react";
import { Company } from "./types/Company.type";

export function CompanyPage() {
  const companyService = new CompanyService(new Api());
  const {
    isOpen: registerModalIsOpen,
    onOpen: registerModalOnOpen,
    onClose: registerModalOnClose,
  } = useDisclosure();
  const [listLoading, setListLoading] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [search, setSearch] = useState<string>("");
  const [ordering, setOrdering] = useState<{
    column: string;
    direction: "asc" | "desc";
  }>({
    column: "name",
    direction: "asc",
  });

  const listCompanies = async () => {
    setListLoading(true);
    const response = await companyService.list({
      search,
      ordering,
    });
    setCompanies(response.data);
    setListLoading(false);
  };

  useEffect(() => {
    listCompanies();
  }, [ordering]);

  return (
    <>
      <Heading size="lg" mb={4}>
        Empresas
      </Heading>

      <List
        loading={listLoading}
        companies={companies}
        ordering={ordering}
        setOrdering={setOrdering}
        search={search}
        setSearch={setSearch}
        refresh={listCompanies}
      />
      <RegisterModal
        isOpen={registerModalIsOpen}
        onClose={registerModalOnClose}
        onRegister={listCompanies}
      />

      <FloatingAddButton
        tooltip="Adicionar empresa"
        redirect={false}
        onClick={registerModalOnOpen}
      />
    </>
  );
}
