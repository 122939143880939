import { Api } from "./api";

export class AuthService {
  constructor(private api: Api) {}

  public async login(params: { email: string; password: string }) {
    return this.api.post("/login", params, {}, false);
  }

  public async getUser() {
    return this.api.get("/me");
  }
}
