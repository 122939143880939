import axios from "axios";

export class Api {
  private token: string | null;

  constructor() {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    this.token = localStorage.getItem("token");
  }

  public async get(
    path: string,
    params: any = {},
    headers: any = {},
    authenticated: boolean = true
  ) {
    if (authenticated) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    return axios.get(path, {
      params,
      headers,
    });
  }

  public async post(
    path: string,
    data: any = {},
    headers: any = {},
    authenticated: boolean = true
  ) {
    if (authenticated) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    return axios.post(path, data, {
      headers,
    });
  }

  public async put(
    path: string,
    data: any = {},
    headers: any = {},
    authenticated: boolean = true
  ) {
    if (authenticated) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    return axios.put(path, data, {
      headers,
    });
  }

  public async delete(
    path: string,
    data: any = {},
    headers: any = {},
    authenticated: boolean = true
  ) {
    if (authenticated) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    return axios.delete(path, {
      data,
      headers,
    });
  }
}
