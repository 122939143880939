import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Company } from "../types/Company.type";
import { formatCnpj } from "../../../helpers/formatters";
import { useEffect, useRef, useState } from "react";
import { CompanyNoteService } from "../../../services/CompanyNoteService";
import { Api } from "../../../services/api";
import { FileIcon } from "../../../components/fileicon/FIleIcon";
import { Confirm } from "../../../components/confirm/confirm";

type ViewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  company: Company | null;
};
type Note = {
  id: number;
  note: string;
  files: {
    id: number;
    url: string;
    name: string;
    extension: string;
  }[];
  created_at: string;
  updated_at: string;
};

export function ViewModal({ isOpen, onClose, company }: ViewModalProps) {
  const noteService = new CompanyNoteService(new Api());
  const toast = useToast();
  const [notes, setNotes] = useState<{ loading: boolean; notes: Note[] }>({
    loading: false,
    notes: [],
  });
  const [deletingNoteId, setDeletingNoteId] = useState<number | null>(null);

  const {
    isOpen: deleteDialogIsOpen,
    onOpen: deleteDialogOnOpen,
    onClose: deleteDialogOnClose,
  } = useDisclosure();
  const [deleting, setDeleting] = useState(false);
  const [form, setForm] = useState<{
    loading: boolean;
    note: string;
    files: File[];
  }>({
    loading: false,
    note: "",
    files: [],
  });
  const initialRef = useRef(null);

  const loadNotes = () => {
    setNotes({ loading: true, notes: [] });

    if (company) {
      noteService.list(company.id).then((response) => {
        setNotes({ loading: false, notes: response.data.notes });
      });
    }
  };

  useEffect(loadNotes, [company]);

  const handleCreateNote = () => {
    if (form.note === "") {
      toast({
        title: "A anotação não pode estar vazia",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setForm({ ...form, loading: true });

    noteService
      .create({
        note: form.note,
        company_id: company!.id,
        files: form.files,
      })
      .then(() => {
        toast({
          title: "Anotação criada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setForm({ loading: false, note: "", files: [] });
        loadNotes();
      })
      .catch((error) => {
        toast({
          title: "Erro ao criar anotação",
          description: error.response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setForm({ ...form, loading: false });
        loadNotes();
      });
  };

  const handleDeleteNote = () => {
    setDeleting(true);

    if (deletingNoteId) {
      noteService.delete(deletingNoteId).then(() => {
        toast({
          title: "Anotação removida com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setDeleting(false);
        loadNotes();
        deleteDialogOnClose();
      });
    }
  };

  return (
    company && (
      <>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          size="4xl"
          scrollBehavior="inside"
          initialFocusRef={initialRef}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader boxShadow="sm">
              <Flex align="center">
                <Image
                  src={`data:image/png;base64,${company.logo}`}
                  alt="Logo Foxbit"
                  h={10}
                  pr={2}
                />
                {company.name} - {formatCnpj(company.cnpj)}
              </Flex>
              <Flex justify="space-between" align="center">
                <Box mt={4}>
                  <Tooltip label="Enviar e-mail" aria-label="Enviar e-mail">
                    <Link href={`mailto:${company.email}`} isExternal>
                      <Button colorScheme="teal" size="sm">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </Button>
                    </Link>
                  </Tooltip>
                  <Tooltip label="Fazer ligação" aria-label="Fazer ligação">
                    <Link href={`tel:${company.phone}`} isExternal>
                      <Button colorScheme="teal" size="sm" ml={2}>
                        <FontAwesomeIcon icon={faPhone} />
                      </Button>
                    </Link>
                  </Tooltip>
                  <Tooltip
                    label="Enviar mensagem via Whatsapp"
                    aria-label="Enviar mensagem via Whatsapp"
                  >
                    <Link href={`https://wa.me/55${company.phone}`} isExternal>
                      <Button colorScheme="teal" size="sm" ml={2}>
                        <FontAwesomeIcon icon={faWhatsapp} />
                      </Button>
                    </Link>
                  </Tooltip>
                </Box>
                <Text fontSize="sm" fontWeight="bold">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(Number(company.payment_value))}
                  /{company.payment_type === "hourly" ? "hora" : "mês"}
                </Text>
              </Flex>
              <Box mt={4}>
                <Text fontSize="sm" color="gray.500">
                  {company.note}
                </Text>
              </Box>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Box>
                <Box
                  mt={4}
                  border="1px solid"
                  borderColor="teal.500"
                  p={4}
                  borderRadius={4}
                  boxShadow="md"
                >
                  <Textarea
                    border="none"
                    borderRadius={0}
                    _focusVisible={{
                      border: "none",
                    }}
                    rows={4}
                    resize="none"
                    mb={2}
                    placeholder="Clique para adicionar uma anotação"
                    ref={initialRef}
                    value={form.note}
                    onChange={(e) => setForm({ ...form, note: e.target.value })}
                  />
                  <Flex justify="space-between" align="center">
                    <Input
                      type="file"
                      p={1}
                      placeholder="Anexar arquivo"
                      w="60%"
                      multiple
                      onChange={(e) => {
                        if (e.target.files) {
                          setForm({
                            ...form,
                            files: Array.from(e.target.files),
                          });
                        }
                      }}
                    />
                    <Button
                      colorScheme="teal"
                      size="sm"
                      onClick={handleCreateNote}
                      isLoading={form.loading}
                    >
                      Adicionar anotação
                    </Button>
                  </Flex>
                </Box>

                <Box mt={4}>
                  {notes.loading
                    ? [...Array(5).keys()].map((i) => (
                        <>
                          <Box mb={2} mt={2}>
                            <SkeletonText noOfLines={1} width="20%" mb={3} />
                            <SkeletonText noOfLines={4} />
                          </Box>
                          <Divider />
                        </>
                      ))
                    : notes.notes.map((note) => (
                        <>
                          <Box mb={2} mt={2}>
                            <Flex justify="space-between">
                              <Text fontSize="sm" fontWeight="bold">
                                {new Date(note.created_at).toLocaleDateString()}{" "}
                                {new Date(note.created_at)
                                  .toLocaleTimeString()
                                  .slice(0, 5)}
                              </Text>
                              <Tooltip
                                label="Excluir anotação"
                                aria-label="Excluir anotação"
                              >
                                <Button
                                  colorScheme="red"
                                  size="xs"
                                  onClick={() => {
                                    setDeletingNoteId(note.id);
                                    deleteDialogOnOpen();
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </Tooltip>
                            </Flex>
                            <Text>{note.note}</Text>
                            {note.files.map((file) => (
                              <Link
                                key={file.id}
                                href={file.url}
                                isExternal
                                color="blue.500"
                                textDecoration="underline"
                              >
                                <Button
                                  size="xs"
                                  colorScheme="teal"
                                  mr={2}
                                  leftIcon={
                                    <FileIcon extension={file.extension} />
                                  }
                                >
                                  {file.name}
                                </Button>
                              </Link>
                            ))}
                          </Box>
                          <Divider />
                        </>
                      ))}

                  {!notes.loading && notes.notes.length === 0 && (
                    <Text fontSize="sm" color="gray.500" textAlign="center">
                      Nenhuma anotação cadastrada :'(
                    </Text>
                  )}
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Confirm
          isOpen={deleteDialogIsOpen}
          onClose={deleteDialogOnClose}
          onConfirm={handleDeleteNote}
          idLoading={deleting}
          title="Você tem certeza?"
          message="Você tem certeza de que deseja remover essa empresa?"
          confirmText="Remover"
          confirmColorScheme="red"
          cancelText="Cancelar"
        />
      </>
    )
  );
}
