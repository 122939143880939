import { Outlet } from "react-router-dom";
import { Navbar } from "../components/navbar/Navbar";
import { Box } from "@chakra-ui/react";

export function DashboardPage() {
  return (
    <>
      <Navbar />

      <Box p={4} w={{ base: "100%", md: "80%" }} mx="auto">
        <Outlet />
      </Box>
    </>
  );
}
