import { Api } from "./api";

export type CreateNoteData = {
  note: string;
  company_id: number;
  files: File[];
};

export class CompanyNoteService {
  constructor(private api: Api) {}

  public async list(companyId: number) {
    return this.api.get("/company_notes", { company_id: companyId });
  }

  public async show(id: number) {
    return this.api.get(`/company_notes/${id}`);
  }

  public async create(data: CreateNoteData) {
    const formData = new FormData();
    formData.append("note", data.note);
    formData.append("company_id", data.company_id.toString());
    data.files.forEach((file) => {
      formData.append("files[]", file);
    });

    return this.api.post("/company_notes", formData);
  }

  public async update(id: number, note: string) {
    return this.api.post(`/company_notes/${id}`, { note });
  }

  public async delete(id: number) {
    return this.api.delete(`/company_notes/${id}`);
  }
}
