import { Button, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export function NotFoundPage() {
  return (
    <>
      <video
        autoPlay
        muted
        loop
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      >
        <source src="/not-found.mp4" type="video/mp4" />
      </video>
      <Flex
        minHeight={"100vh"}
        alignItems={"center"}
        justifyContent={"center"}
        direction="column"
        background="rgba(0, 0, 0, 0.8)"
      >
        <Text fontSize="8xl" color="white" fontWeight="bold">
          404
        </Text>
        <Text fontSize="4xl" color="white" fontWeight="bold">
          Ops! O que você procura não está aqui.
        </Text>
        <Link to="/">
          <Button
            colorScheme="gray"
            variant="solid"
            mt={6}
            size="lg"
            px={8}
            py={4}
          >
            Voltar para o início
          </Button>
        </Link>
      </Flex>
    </>
  );
}
