import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";

type ConfirmProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  message?: string;
  confirmText?: string;
  confirmColorScheme?: string;
  cancelText?: string;
  cancelColorScheme?: string;
  isCentered?: boolean;
  idLoading: boolean;
};

export function Confirm({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmText,
  confirmColorScheme,
  cancelText,
  cancelColorScheme,
  isCentered,
  idLoading,
}: ConfirmProps) {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      isCentered={isCentered ?? true}
      closeOnEsc={!idLoading}
      closeOnOverlayClick={!idLoading}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title ?? "Você tem certeza?"}</AlertDialogHeader>
          <AlertDialogBody>
            {message ?? "Você não poderá desfazer essa ação."}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              colorScheme={cancelColorScheme ?? "gray"}
              disabled={idLoading}
            >
              {cancelText ?? "Cancelar"}
            </Button>
            <Button
              colorScheme={confirmColorScheme ?? "teal"}
              onClick={onConfirm}
              ml={3}
              isLoading={idLoading}
            >
              {confirmText ?? "Confirmar"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
