import {
  Button,
  Flex,
  FormControl,
  Image,
  Input,
  InputGroup,
  Link,
  SkeletonText,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { faEye, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ViewModal } from "./ViewModal";
import { Company } from "../types/Company.type";
import { formatPhone } from "../../../helpers/formatters";
import { useState } from "react";
import { CompanyService } from "../../../services/CompanyService";
import { Api } from "../../../services/api";
import { Confirm } from "../../../components/confirm/confirm";

type ListProps = {
  loading: boolean;
  companies: Company[];
  ordering: {
    column: string;
    direction: "asc" | "desc";
  };
  setOrdering: ({
    column,
    direction,
  }: {
    column: string;
    direction: "asc" | "desc";
  }) => void;
  search: string;
  setSearch: (search: string) => void;
  refresh: () => void;
};
export function List({
  loading,
  companies,
  ordering,
  setOrdering,
  search,
  setSearch,
  refresh,
}: ListProps) {
  const companyService = new CompanyService(new Api());
  const [activeCompany, setActiveCompany] = useState<Company | null>(null);
  const {
    isOpen: deleteDialogIsOpen,
    onOpen: deleteDialogOnOpen,
    onClose: deleteDialogOnClose,
  } = useDisclosure();
  const [deleting, setDeleting] = useState(false);
  const {
    isOpen: viewModalIsOpen,
    onOpen: viewModalOnOpen,
    onClose: viewModalOnClose,
  } = useDisclosure();

  const handleView = (company: Company) => {
    setActiveCompany(company);
    viewModalOnOpen();
  };

  const handleDelete = (company: Company) => {
    setActiveCompany(company);
    deleteDialogOnOpen();
  };

  const executeDelete = () => {
    if (activeCompany) {
      setDeleting(true);
      companyService.delete(activeCompany.id).then(() => {
        refresh();
        deleteDialogOnClose();
        setDeleting(false);
      });
    }
  };

  const handleOrdering = (column: string) => {
    if (ordering.column === column) {
      setOrdering({
        column,
        direction: ordering.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setOrdering({
        column,
        direction: "asc",
      });
    }
  };

  return (
    <>
      <FormControl w={{ base: "100%", md: "30%" }} mb={4} ml="auto">
        <form
          onSubmit={(event: React.FormEvent) => {
            event.preventDefault();
            refresh();
          }}
        >
          <InputGroup>
            <Input
              type="search"
              placeholder="Pesquisar empresa..."
              borderRightRadius={0}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              type="submit"
              colorScheme="teal"
              variant="solid"
              borderLeftRadius={0}
              isLoading={loading}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
        </form>
      </FormControl>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th
              className={`orderable ${
                ordering.column === "id" ? ordering.direction : ""
              }`}
              onClick={() => {
                handleOrdering("id");
              }}
            >
              #
            </Th>
            <Th
              className={`orderable ${
                ordering.column === "name" ? ordering.direction : ""
              }`}
              onClick={() => {
                handleOrdering("name");
              }}
            >
              Nome
            </Th>
            <Th
              className={`orderable ${
                ordering.column === "email" ? ordering.direction : ""
              }`}
              onClick={() => {
                handleOrdering("email");
              }}
            >
              E-mail
            </Th>
            <Th
              className={`orderable ${
                ordering.column === "phone" ? ordering.direction : ""
              }`}
              onClick={() => {
                handleOrdering("phone");
              }}
            >
              Telefone
            </Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading &&
            [...Array(10).keys()].map((i) => (
              <Tr key={i}>
                <Td>
                  <SkeletonText noOfLines={1} />
                </Td>
                <Td>
                  <SkeletonText noOfLines={1} />
                </Td>
                <Td>
                  <SkeletonText noOfLines={1} />
                </Td>
                <Td>
                  <SkeletonText noOfLines={1} />
                </Td>
                <Td>
                  <SkeletonText noOfLines={1} />
                </Td>
              </Tr>
            ))}

          {!loading &&
            (companies.length === 0 ? (
              <Tr>
                <Td colSpan={5} textAlign="center">
                  Nenhuma empresa cadastrada
                </Td>
              </Tr>
            ) : (
              companies.map((company, i) => (
                <Tr key={i}>
                  <Td>{company.id}</Td>
                  <Td>
                    <Flex align="center">
                      <Image
                        src={`data:image/png;base64,${company.logo}`}
                        alt="Logo Foxbit"
                        h={6}
                        pr={2}
                      />
                      {company.name}
                    </Flex>
                  </Td>
                  <Td>
                    <Link
                      href={`mailto:${company.email}`}
                      isExternal
                      color="blue.500"
                      textDecoration="underline"
                    >
                      {company.email}
                    </Link>
                  </Td>
                  <Td>{formatPhone(company.phone)}</Td>
                  <Td>
                    <Tooltip label="Visualizar" aria-label="Visualizar">
                      <Button
                        colorScheme="teal"
                        size="sm"
                        onClick={() => handleView(company)}
                        onFocus={(e) => e.preventDefault()}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </Tooltip>
                    <Tooltip label="Remover" aria-label="Remover">
                      <Button
                        colorScheme="red"
                        size="sm"
                        ml={2}
                        onClick={() => handleDelete(company)}
                        onFocus={(e) => e.preventDefault()}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Tooltip>
                  </Td>
                </Tr>
              ))
            ))}
        </Tbody>
        <TableCaption textAlign="right">
          {loading ? (
            <SkeletonText noOfLines={1} w="150px" ml="auto" />
          ) : companies.length === 0 ? (
            ""
          ) : companies.length === 1 ? (
            "1 empresa cadastrada"
          ) : (
            `${companies.length} empresas cadastradas`
          )}
        </TableCaption>
      </Table>

      <ViewModal
        isOpen={viewModalIsOpen}
        onClose={viewModalOnClose}
        company={activeCompany as unknown as Company}
      />

      <Confirm
        isOpen={deleteDialogIsOpen}
        onClose={deleteDialogOnClose}
        onConfirm={executeDelete}
        idLoading={deleting}
        title="Você tem certeza?"
        message="Você tem certeza de que deseja remover essa empresa?"
        confirmText="Remover"
        confirmColorScheme="red"
        cancelText="Cancelar"
      />
    </>
  );
}
