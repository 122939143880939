import { Api } from "./api";

export type CreateCompanyData = {
  name: string;
  social_name: string;
  cnpj: string;
  email: string;
  phone: string;
  logo: File;
  payment_type: "hourly" | "monthly";
  payment_value: number;
  note: string;
};

export class CompanyService {
  constructor(private api: Api) {}

  public async list(params: {
    search: string;
    ordering: { column: string; direction: "asc" | "desc" };
  }) {
    const paramsData = new FormData();
    paramsData.append("search", params.search);
    paramsData.append("ordering[column]", params.ordering.column);
    paramsData.append("ordering[direction]", params.ordering.direction);

    return this.api.get("/companies", params);
  }

  public async show(id: number) {
    return this.api.get(`/companies/${id}`);
  }

  public async create(data: CreateCompanyData) {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("social_name", data.social_name);
    formData.append("cnpj", data.cnpj.replace(/\D/g, ""));
    formData.append("email", data.email);
    formData.append("phone", data.phone.replace(/\D/g, ""));
    formData.append("logo", data.logo);
    formData.append("payment_type", data.payment_type);
    formData.append("payment_value", String(data.payment_value));
    formData.append("note", data.note);

    return this.api.post("/companies", formData);
  }

  public async delete(id: number) {
    return this.api.delete(`/companies/${id}`);
  }
}
