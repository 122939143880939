import { Button, Tooltip } from "@chakra-ui/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

type FloatingAddButtonProps = {
  tooltip: string;
  redirect?: boolean;
  href?: string;
  onClick?: () => void;
};
export function FloatingAddButton({
  tooltip,
  redirect,
  href,
  onClick,
}: FloatingAddButtonProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (redirect) {
      navigate(href!);
    } else {
      onClick!();
    }
  };

  return (
    <Tooltip
      label={tooltip}
      aria-label={tooltip}
      hasArrow={true}
      placement="left"
    >
      <Button
        colorScheme="teal"
        size="lg"
        position="fixed"
        right={4}
        bottom={4}
        onClick={handleClick}
        borderRadius="full"
        zIndex={10}
        width={16}
        height={16}
        boxShadow="xl"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </Tooltip>
  );
}
